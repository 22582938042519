import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import slug from "slug"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"

const ImageSetContainer = styled.div`
  width: 80vw;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 90vw;
  }
`

const ImageSetContainerLink = styled(Link)`
  width: 80vw;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 90vw;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: ${({ length }) => `${Math.round(100 / length)}%`};
  overflow: hidden;
`

const Image = styled.img`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s, filter 0.6s;
  &:hover {
    filter: saturate(1.68);
  }
`

const Placeholder = styled.img`
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  filter: ${({ isLoaded }) =>
    isLoaded ? "blur(0px) saturate(1)" : "blur(20px) saturate(1)"};
  width: 100%;
  transition: opacity 0.6s, filter 0.3s;
  &:hover {
    filter: ${({ isLoaded }) =>
      isLoaded ? "blur(0px) saturate(1.4)" : "blur(20px) saturate(1.4)"};;
  },
`

export default props => {
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({ triggerOnce: true })
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    images: { images },
    project,
  } = props

  const Wrapper = project ? ImageSetContainerLink : ImageSetContainer

  return (
    <Wrapper
      to={project ? `/project/${slug(project.name, { lower: true })}` : null}
      ref={ref}
    >
      {images.map(image => (
        <ImageContainer length={images.length} key={image._key}>
          <Placeholder
            src={image.asset.fluid.base64}
            alt={image.altText}
            isLoaded={isLoaded}
          />
          {inView || supportsLazyLoading ? (
            <Image
              srcSet={
                image.asset.extension === "gif"
                  ? image.asset.url
                  : image.asset.fluid.srcSet
              }
              sizes={image.asset.fluid.sizes}
              alt={image.altText}
              {...props}
              isLoaded={isLoaded}
              loading="lazy"
              onLoad={() => {
                setIsLoaded(true)
              }}
            />
          ) : null}
        </ImageContainer>
      ))}
    </Wrapper>
  )
}
