import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Tag from "./tag"

const Tags = styled.div`
  width: 100%;
  margin: 3rem 0;
  text-align: center;
`

export default ({ projectTags, highlight }) => {
  // flatten project tags to array
  const projectTagsArray = projectTags.map(projectTag => projectTag.name)
  const data = useStaticQuery(graphql`
    query TemplateTagsQuery {
      allSanityTag {
        edges {
          node {
            name
            _id
          }
        }
      }
    }
  `)

  return (
    <Tags>
      {data.allSanityTag.edges.map(({ node: tag }, index) => {
        // check if this tag is tagged on this project
        const isProjectTag = projectTagsArray.includes(tag.name)
        return (
          <Tag
            tag={tag}
            key={`${Math.random() * index}-tag.id`}
            isProjectTag={isProjectTag}
            highlight={highlight}
          />
        )
      })}
    </Tags>
  )
}

// get all tags
