import React, { useState } from "react"
import styled from "styled-components"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"

const ImageContainer = styled.div` 
  position: relative;
  margin: 2rem auto;
  width: 100%;
  ${"" /* if wider than tall */}
  max-width: ${({ aspectRatio, isFullWidth }) => {
    const width = isFullWidth ? "100vw" : "80vw"
    return aspectRatio > 1 ? width : ""
  }};
  ${"" /* if taller than wide */}
  width: ${({ aspectRatio }) => (aspectRatio <= 1 ? "fit-content" : "")};
  height: ${({ aspectRatio }) => (aspectRatio <= 1 ? "90vh" : "initial")};
  max-height: ${({ aspectRatio }) => (aspectRatio <= 1 ? "90vh" : "initial")};
  text-align: center;
  overflow: hidden; 
  transition: filter 0.3s; 
  @media (max-width: 800px) {
    width: 100%;
    max-width: 90vw; 
    height: initial; 
    max-height:initial; 
  }
  &:hover {
    filter: saturate(1.68);
  }
`

const Placeholder = styled.img`
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  filter: ${({ isLoaded }) => (isLoaded ? "blur(0px)" : "blur(20px)")};
  ${"" /* if wider than tall */}
  width: ${({ aspectRatio }) => (aspectRatio > 1 ? "100%" : "initial")};
  ${"" /* if taller than wide */}
  height: ${({ aspectRatio }) => (aspectRatio <= 1 ? "100%" : "initial")};
  transition: filter 0.3s, opacity: 0.6s; 
  overflow: hidden; 
  @media (max-width: 800px) {
    width: 100%;
    height: auto; 
  }
`

const Image = styled.img`
  opacity: ${isLoaded => (isLoaded ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.6s, filter 0.6s;
`

export default props => {
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({
    triggerOnce: true,
    marginRoot: "100px 0 0 0",
  })
  const [isLoaded, setIsLoaded] = useState(false)
  const { image } = props
  if (!image.asset) return null

  const handleImageLoaded = () => {
    setIsLoaded(true)
  }

  return (
    <ImageContainer
      aspectRatio={image.asset.fluid.aspectRatio}
      isFullWidth={image.isFullWidth}
      ref={ref}
    >
      <Placeholder
        src={image.asset.fluid.base64}
        alt={image.altText}
        aspectRatio={image.asset.fluid.aspectRatio}
        isLoaded={isLoaded}
      />
      {inView || supportsLazyLoading ? (
        <Image
          srcSet={
            image.asset.extension === "gif"
              ? image.asset.url
              : image.asset.fluid.srcSet
          }
          sizes={image.asset.fluid.sizes}
          src={image.asset.url}
          alt={image.altText}
          isLoaded={isLoaded}
          onLoad={handleImageLoaded}
          loading="lazy"
          aspectRatio={image.asset.fluid.aspectRatio}
        />
      ) : null}
    </ImageContainer>
  )
}
