import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

// Import Contexts
import FilterContext from "~context/filter"

// Import Global Components
import PillButton from "~components/pillButton"

const StyledPillButton = styled(PillButton)`
  display: ${({ isProjectTag }) => (isProjectTag ? "inline-block" : "none")};
  color: ${({ active }) => (active ? "red" : "auto")};
`

export default ({ tag, isProjectTag, highlight }) => {
  const [filters, setFilters] = useContext(FilterContext)
  const [active, setActive] = useState(filters.includes(tag.name))

  const handleClick = () => {
    if (active) {
      setFilters(
        filters.filter(filter => {
          return filter !== tag.name
        })
      )
    } else {
      setFilters(filters.concat([tag.name]))
    }
  }

  useEffect(() => {
    if (filters.includes(tag.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [filters])

  if (active) {
    return (
      <StyledPillButton
        isProjectTag={isProjectTag}
        title={tag.name}
        handleClick={handleClick}
        active={active}
        highlight={highlight}
      />
    )
  } else {
    return (
      <StyledPillButton
        isProjectTag={isProjectTag}
        value={tag.name}
        handleClick={handleClick}
        active={active}
        highlight={highlight}
      />
    )
  }
}
