import React from "react"
import styled from "styled-components"

// Import Local Components
import Image from "~components/image"
import ImageSet from "~components/imageSet"
import Video from "~components/video"

const Images = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
`

export default ({ images, project }) => {
  if (!images) return null
  return (
    <Images>
      {images.map(image => {
        switch (image.__typename) {
          case "SanityImageSet":
            return (
              <ImageSet images={image} key={image._key} project={project} />
            )
          case "SanityVideoUrl":
            return <Video videoUrl={image.videoUrl} key={image._key} />
          default:
            return <Image image={image} key={image._key} project={project} />
        }
      })}
    </Images>
  )
}
