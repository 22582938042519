import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

const Wrapper = styled.div`
  width: 90vw;
  margin: 2rem auto;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

export default ({ videoUrl }) => {
  return (
    <Wrapper>
      <Player url={videoUrl} width="100%" height="100%" />
    </Wrapper>
  )
}
