import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import slug from "slug"

import Image from "~components/image"

const Hero = styled(Link)`
  display: block;
  position: relative;
  text-decoration: none;
  transition: filter 0.6s;
  &:hover > * {
    filter: saturate(1.68) !important;
  }
`
Hero.Image = styled(Image)`
  transition: filter 0.6s;
`

Hero.TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
Hero.Text = styled.h2`
  margin: 0;
  max-width: 65%;
  color: var(--color-background);
  font-size: 5rem;
  font-weight: lighter;
  text-align: left;
  line-height: normal;
  white-space: pre-wrap;
  @media (max-width: 800px) {
    font-size: 2rem;
  }
`

export default ({ image, projectName }) => (
  <Hero to={`/project/${slug(projectName, { lower: true })}`}>
    <Hero.Image image={image} />
    <Hero.TextWrapper>
      <Hero.Text>{projectName}</Hero.Text>
    </Hero.TextWrapper>
  </Hero>
)
