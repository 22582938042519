import styled from "styled-components"

import Hero from "./components/hero"
import Description from "./components/description"
import Credits from "./components/credits"
import Tags from "./components/tags"
import Images from "./components/images"
import Video from "~components/video"
import IntersectionObserver from "./components/intersectionObserver"
const Project = styled.div`
  margin: 5rem auto;
  ${"" /* min-height: 100vh; */}
`

Project.Hero = Hero
Project.Description = Description
Project.Credits = Credits
Project.Tags = Tags
Project.Images = Images
Project.IntersectionObserver = IntersectionObserver
Project.Video = Video

export default Project
