/* 
    Intersection Observer

    When this component enters the viewport, it updates the navigation state 
    to the associated project. 
*/

import React, { useEffect, useContext } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

const IntersectionObserver = styled.div``

export default ({ projectTags, project }) => {
  const [ref, inView] = useInView({ threshold: 1 })
  const [, setNavigationState] = useContext(
    NavigationIntersectionObserverContext
  )

  useEffect(() => {
    if (inView) {
      setNavigationState({
        isVisible: true,
        showContact: false,
        data: {
          name: project.name,
          client: project.client,
          description: project.description,
          credits: [project.credits[0]],
          tags: projectTags,
          highlight: project.highlight.hex,
        },
      })
    }
  }, [inView])

  return <IntersectionObserver ref={ref} />
}
